import { ReportsList } from "./List/List";
import { ReportsCreate } from "./Create/Index";
import { ReportsEdit } from "./Edit/Index";

const ReportsResource = {
  List: ReportsList,
  Edit: ReportsEdit,
  Create: ReportsCreate,
};

export default ReportsResource;
