import { useState } from "react";
import { BasicDetails } from "./BasicDetails";
import { EditTabs } from "../../General/utils/EditTabs";
import { StatusButtons } from "../../General/views/StatusButtons";
import { Container, createStyles, makeStyles } from "@material-ui/core";
import { EditBottomToolBar } from "../../General/utils/EditBottomToolBar";
import {
  Edit,
  TopToolbar,
  useAuthenticated,
  FormWithRedirect,
} from "react-admin";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginLeft: theme.spacing(2),
    },
    root: {
      "& .MuiTabs-root": {
        paddingBottom: "0px",
        "& .MuiButtonBase-root": {
          flex: "1",
          borderBottom: "1px solid #4C4B4C",
          maxWidth: "100%",
        },
      },
    },
  })
);

const PostEditActions = (props) => (
  <TopToolbar>
    <StatusButtons {...props} />
  </TopToolbar>
);

const validatePasswords = ({ password, confirm_password }) => {
  const errors = {};

  if (password && confirm_password && password !== confirm_password) {
    errors.confirm_password =
      "The password confirmation is not the same as the password.";
  }
  return errors;
};

export const ReportsEdit = (props) => {
  useAuthenticated();
  const tabsWithDetails = {
    basic_details: {
      name: "Edit Report Details",
      component: <BasicDetails />,
    },
  };
  const tabIds = Object.keys(tabsWithDetails);
  const [currentTabId, setCurrentTabId] = useState(tabIds[0]);

  const classes = useStyles();
  return (
    <Edit
      className={classes.root}
      actions={<PostEditActions />}
      title="Reports Details"
      mutationMode="pessimistic"
      {...props}
    >
      <FormWithRedirect
        {...props}
        validate={validatePasswords}
        render={(formProps) => [
          <EditTabs
            key="tabs"
            currentTabId={currentTabId}
            tabsWithDetails={tabsWithDetails}
            setCurrentTabId={setCurrentTabId}
          />,
          <Container key="content">
            {tabsWithDetails[currentTabId].component}
          </Container>,
          <EditBottomToolBar key="toolbar" {...formProps} />,
        ]}
      />
    </Edit>
  );
};
