export const titleCase = (str = "") => {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
export const renderStatus = (str = "") => {
  return titleCase(str.replaceAll("_", " "));
};
