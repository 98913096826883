import {
  Datagrid,
  DateField,
  TextField,
  CreateButton,
  FunctionField,
  useRedirect,
  ReferenceManyField,
} from "react-admin";
import { EmptyList } from "../../General/views/EmptyList";
import { StatusField } from "../../General/utils/StatusField";
import { Box, Grid, makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    index: {
      display: "none",
    },
    accordion_details: {
      display: "block",
      padding: "0",
      borderBottom: "0!important",
      "& .MuiAccordionSummary-root": {
        padding: "0",
        maxWidth: "150px",
      },
    },
    colorTextgrey: {
      color: "#ffffff",
    },
    ptb: {
      paddingTop: "0!important",
      paddingBottom: "0!important",
    },
    root: {
      paddingTop: theme.spacing(10),
    },
  })
);

export const Admins = (props) => {
  const redirect = useRedirect();
  const classes = useStyles();
  return (
    <Box component="section" className={`${classes.root} myforms`}>
      <Grid
        style={{ paddingBottom: "50px", flexDirection: "row-reverse" }}
        container
        spacing={10}
      >
        <CreateButton basePath="/admins" label="Add New Admin" />
      </Grid>

      <Grid style={{ paddingBottom: "50px" }} container spacing={10}>
        <ReferenceManyField reference="admins" target="id" addLabel={false}>
          <Datagrid
            empty={<EmptyList itemName={"admin"} {...props} />}
            rowClick={(id) => redirect(`/admins/${id}`)}
            optimized
          >
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="email" />
            <FunctionField
              label="Status"
              source="status"
              render={(record) => <StatusField status={record.status} />}
            />
            <DateField source="created_at" />
          </Datagrid>
        </ReferenceManyField>
      </Grid>
    </Box>
  );
};
