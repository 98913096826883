import * as React from "react";
import { Route } from "react-router-dom";
import dataProvider from "./data_provider";
import { Admin, Resource } from "react-admin";
import { authProvider } from "./auth_provider";
import Layout from "./components/Layout/Index";
import { Login } from "./components/Login/Index";
import { theme } from "./components/Layout/theme";
import Dashboard from "./components/Dashboard/Index";
import StaffResource from "./components/Staff/Index";
import OwnersResource from "./components/Owners/Index";
import ClientsResource from "./components/Clients/Index";
import AdminProfileResource from "./components/Admin/Index";
import { ResetPassword } from "./components/Login/ResetPass";
import { ForgotPassword } from "./components/Login/ForgotPass";
import AddUserResource from "./components/AddUser/Index";
import ReportsResource from "./components/Reports/Index";

const App = () => {
  return (
    <Admin
      title=""
      dashboard={Dashboard}
      authProvider={authProvider}
      loginPage={Login}
      theme={theme}
      dataProvider={dataProvider}
      layout={Layout}
      customRoutes={[
        <Route
          key="my-profile"
          path="/my-profile"
          component={AdminProfileResource.MyProfile}
        />,
        <Route
          exact
          path="/forgotpassword"
          component={ForgotPassword}
          noLayout
        />,
        <Route
          exact
          path="/resetpassword/:id"
          component={ResetPassword}
          noLayout
        />,
      ]}
    >
      <Resource
        name="profile"
        edit={AdminProfileResource.Edit}
        create={AdminProfileResource.Create}
      />
      <Resource name="tickets" />
      <Resource
        name="getReports"
        list={ReportsResource.List}
        edit={ReportsResource.Edit}
        create={ReportsResource.Create}
      />

      <Resource
        name="owners"
        list={OwnersResource.List}
        edit={OwnersResource.Edit}
      />

      <Resource
        name="clients"
        list={ClientsResource.List}
        edit={ClientsResource.Edit}
      />

      <Resource
        name="staff"
        list={StaffResource.List}
        edit={StaffResource.Edit}
      />

      <Resource name="addUser" create={AddUserResource.Edit} />
    </Admin>
  );
};

export default App;
