import { createTheme as createMuiTheme } from "@material-ui/core/styles";

const defaults = {
  charcoalColor: { root: "#09102A" },
  tealColor: {
    main: "#5A35B6",
    light: "#42ffe2",
    dark: "#269483",
  },
  pinkColor: {
    main: "#5A35B6",
    light: "#F46868",
    dark: "#F46868",
  },
  orangeColor: {
    main: "#5A35B6",
    light: "#f89406",
    dark: "#f89406",
  },
};

export const theme = createMuiTheme({
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    h1: {
      "@media (max-width:1280px)": {
        fontSize: "24px !important",
      },
    },
    h3: {
      color: "#ffffff",
    },
    h4: {
      fontSize: "1.125rem",
      fontWeight: "bold",
    },
    h6: {
      "@media (max-width:1280px)": {
        fontSize: "16px !important",
      },
    },
    body1: {
      color: "#ffffff",
      textDecoration: "none",
      "@media (max-width:1280px)": {
        fontSize: 14,
      },
    },
  },

  palette: {
    type: "light",
    primary: defaults.tealColor,
    secondary: defaults.tealColor,
    invisible: defaults.charcoalColor.root,
    error: defaults.pinkColor,
    warning: defaults.orangeColor,
  },

  overrides: {
    RaToolbar: {
      toolbar: {
        backgroundColor: "#0d1841",
        marginTop: "40px!important",
      },
    },
    RaLayout: {
      content: {
        backgroundColor: "#09102A",
        zIndex: "9999",
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#0d1841",
      },
    },
    RaButton: {
      button: {
        width: defaults.buttonWidth,
      },
    },
    RaDeleteButton: {
      root: {
        backgroundColor: defaults.charcoalColor.root,
      },
    },
  },
});
