import { renderStatus } from "../../../functions/string";
import { Typography, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    active: {
      color: theme.palette.primary.main,
    },
    on_hold: {
      color: theme.palette.warning.main,
    },
    blocked: {
      color: theme.palette.error.main,
    },
    cancelled: {
      color: theme.palette.warning.main,
    },
    bid_withdrawn: {
      color: theme.palette.error.main,
    },
    expired: {
      color: "inherit",
    },
    under_review: {
      color: "#71b1a6",
    },
  })
);

export const StatusField = ({ status, variant = "body1" }) => {
  const classes = useStyles();

  return (
    <Typography
      display="inline"
      key={`button-edit`}
      variant={variant}
      classes={{ root: classes[status] }}
    >
      {renderStatus(status)}
    </Typography>
  );
};
