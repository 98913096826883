import { useState } from "react";
import {
  Button,
  Confirm,
  useDataProvider,
  useRefresh,
  useNotify,
} from "react-admin";
import { Grid } from "@material-ui/core";

export const StatusButtons = ({
  data: record,
  basePath,
  data,
  resource,
  ...props
}) => {
  const title = "Are you sure to delete this entity ?";
  const content = "The updation will delete it permenently";

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);
  const toggleClick = (state = !open) => {
    setOpen(state);
  };

  const confirmUpdate = () => {
    dataProvider
      .delete(`${resource}`, {
        id: record.id,
      })
      .then((response) => {
        refresh();
        notify("Status Updated!");
      })
      .catch((error) => notify(`Unknown error occurred`, "warning"));
    setOpen(false);
  };

  const update = (type) => {
    setOpen(true);
  };

  const DeleteButton = () => {
    const allowedStatusesForCancel = [
      "active",
      "accepted",
      "provider_on_the_way",
      "provider_arrived",
    ];

    if (allowedStatusesForCancel) {
      return (
        <Button
          key="delete-button"
          onClick={() => update("delete")}
          label="DELETE"
          className="btnred"
        />
      );
    } else {
      return null;
    }
  };

  return [
    <Grid key="status-buttons-btn" item xs={3}>
      <Grid container justify="flex-end">
        {[
          DeleteButton(),
          <Confirm
            key={`confirmation-popup`}
            isOpen={open}
            title={title}
            content={content}
            onConfirm={confirmUpdate}
            onClose={() => toggleClick(false)}
          />,
        ]}
      </Grid>
    </Grid>,
  ];
};
