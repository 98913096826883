import {
  List,
  Datagrid,
  TextField,
  useListContext,
  useDataProvider,
  useAuthenticated,
  ListContextProvider,
} from "react-admin";
import { Fragment, useEffect, useState } from "react";
import { Divider } from "@material-ui/core";
import { EmptyList } from "../../General/views/EmptyList";

const TabbedDatagrid = (props) => {
  const listContext = useListContext();

  return (
    <Fragment>
      <Divider />
      <ListContextProvider value={{ ...listContext }}>
        <Datagrid
          {...props}
          optimized
          rowClick="edit"
          empty={<EmptyList itemName={"Reports"} {...props} />}
        >
          <TextField source="full_name" />
          <TextField source="url" label="Url" />
        </Datagrid>
      </ListContextProvider>
    </Fragment>
  );
};

export const ReportsList = (props) => {
  useAuthenticated();
  const [reportsData, setReportsData] = useState("");
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .getList("getReports", {
        pagination: { page: 1, perPage: 5 },
        sort: { field: "title", order: "ASC" },
        filter: { author_id: 12 },
      })
      .then((response) => setReportsData(response.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reportsDataChoices = () => {
    if (!reportsData) return [];
    return (reportsData ?? []).map((report) => (
      <iframe src={report.url} title={report.url} />
    ));
  };

  const role = localStorage.getItem("role");
  if (role === "Super Admin") {
    return (
      <List
        {...props}
        exporter={false}
        bulkActionButtons={false}
        title="Reports"
        //   filters={<Filters />}
        filterDefaultValues={{ status: "all" }}
        sort={{ field: "created_at", order: "DESC" }}
      >
        <TabbedDatagrid />
      </List>
    );
  } else {
    return <div className="iframe__wrapper">{reportsDataChoices()}</div>;
  }
};
