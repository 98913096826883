import {
  useAuthenticated,
  SimpleForm,
  TextInput,
  useNotify,
  required,
  Create,
  useRedirect,
} from "react-admin";
import { createStyles, makeStyles } from "@material-ui/core";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginLeft: theme.spacing(2),
    },
    root: {
      "& .MuiTabs-root": {
        paddingBottom: "0px",
        "& .MuiButtonBase-root": {
          flex: "1",
          borderBottom: "1px solid #4C4B4C",
          maxWidth: "100%",
        },
      },
    },
    SimpleForm: {
      padding: "50px 30px",
    },
    ptb: {
      paddingTop: "0!important",
      paddingBottom: "0!important",
    },
  })
);

export const CustomersEdit = (props) => {
  useAuthenticated();
  const notify = useNotify();
  const classes = useStyles();
  const redirect = useRedirect();

  const onSuccess = () => {
    redirect("/addUser/create");
    notify(`User added successfully.`, { type: "success" });
  };

  const onFailure = (data) => {
    notify(data.message, { type: "warning" });
  };

  return (
    <Create
      className="mybg"
      title="Add User"
      redirect={false}
      mutationMode="pessimistic"
      onSuccess={onSuccess}
      onFailure={onFailure}
      {...props}
    >
      <SimpleForm className={classes.SimpleForm}>
        <Grid style={{ width: "100%" }} container spacing={10}>
          <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
            <TextInput fullWidth source="full_name" validate={required()} />
          </Grid>
          <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
            <TextInput fullWidth source="username" validate={required()} />
          </Grid>
          <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
            <TextInput fullWidth source="email" validate={required()} />
          </Grid>
          <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
            <TextInput fullWidth source="postal_code" validate={required()} />
          </Grid>
          <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
            <TextInput fullWidth source="phone" validate={required()} />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};
