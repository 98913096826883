import { TextInput, SelectInput } from "react-admin";
import {
  Box,
  Grid,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    index: {
      display: "none",
    },
    accordion_details: {
      display: "block",
      padding: "0",
      borderBottom: "0 !important",
      "& .MuiAccordionSummary-root": {
        padding: "0",
        maxWidth: "150px",
      },
    },
    colorTextgrey: {
      color: "#ffffff",
    },
    ptb: {
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
    },
    root: {
      "& .MuiSelect-root, .MuiInputBase-input": {
        paddingLeft: "0",
        paddingRight: "0",
        marginTop: "10px",
        color: "#ffffff",
      },
      "& .MuiFilledInput-underline": {
        "&:before": {
          borderBottom: "1px solid #ffffff",
        },
      },
    },
  })
);

export const BasicDetails = (props) => {
  const classes = useStyles();
  return [
    <Box component="section" className={`${classes.root} myforms`}>
      <Typography style={{ paddingBottom: "60px" }}>
        {/* Account Information */}
      </Typography>

      <Grid style={{ width: "100%" }} container spacing={10}>
        <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
          <TextInput fullWidth source="full_name" />
        </Grid>
        <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
          <TextInput fullWidth source="username" />
        </Grid>
        <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
          <TextInput fullWidth source="email" />
        </Grid>
        <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
          <TextInput fullWidth source="postal_code" />
        </Grid>
        <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
          <TextInput fullWidth source="phone" />
        </Grid>
        <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
          <SelectInput
            fullWidth
            source={"status"}
            choices={[
              { id: "Active", name: "Active" },
              { id: "Block", name: "Block" },
            ]}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={10}
        style={{ width: "100%", paddingBottom: "50px" }}
      >
        <Grid
          style={{ width: "100%", paddingBottom: "50px" }}
          className={classes.ptb}
          item
          xl={12}
          md={12}
          sm={12}
        ></Grid>
      </Grid>
    </Box>,
  ];
};
