import { useState, useEffect } from "react";
import {
  useAuthenticated,
  SimpleForm,
  TextInput,
  useNotify,
  required,
  Create,
  SelectInput,
  useRedirect,
} from "react-admin";
import { createStyles, makeStyles } from "@material-ui/core";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginLeft: theme.spacing(2),
    },
    root: {
      "& .MuiTabs-root": {
        paddingBottom: "0px",
        "& .MuiButtonBase-root": {
          flex: "1",
          borderBottom: "1px solid #4C4B4C",
          maxWidth: "100%",
        },
      },
    },
    SimpleForm: {
      padding: "50px 30px",
    },
    ptb: {
      paddingTop: "0!important",
      paddingBottom: "0!important",
    },
  })
);

export const ReportsCreate = (props) => {
  useAuthenticated();
  const classes = useStyles();
  const redirect = useRedirect();

  const [addReports] = useState([]);
  const notify = useNotify();
  const token = localStorage.getItem("auth");
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/admin/getAllClients`, {
      method: "GET",
      headers: new Headers({
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    }).catch((e) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addReportChoices = () => {
    if (!addReports) return [];
    return (addReports ?? []).map((report) => {
      return {
        id: report.id,
        name: report.full_name,
      };
    });
  };

  const onSuccess = ({ data }) => {
    redirect("/getReports");
    notify(data.message ?? `Report Created successfully.`);
  };

  return (
    <Create
      className="mybg"
      {...props}
      title="Reports"
      redirect={false}
      onSuccess={onSuccess}
    >
      <SimpleForm className={classes.SimpleForm}>
        <Grid style={{ width: "100%" }} container spacing={10}>
          <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
            <TextInput fullWidth source="url" validate={required()} />
          </Grid>

          <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
            <SelectInput
              fullWidth
              validate={required()}
              source={"client_id"}
              choices={addReportChoices()}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};
