import React from "react";
import Menu from "@material-ui/core/Menu";
import { MenuItemLink } from "react-admin";
import Button from "@material-ui/core/Button";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& .MuiMenu-list": {
        background: "#ffffff",
      },
    },
  })
);

export function MyUserMenu(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userName = localStorage.getItem("full_name");

  const { children, logout } = props;
  if (!logout && !children) return null;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <AccountCircleIcon style={{ marginRight: "5px" }} />
        {userName}
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.root}
      >
        <MenuItemLink
          to="/my-profile"
          primaryText="My Profile"
          leftIcon={<PermIdentityIcon />}
          onClick={handleClose} // close the menu on click
        />
        {logout}
      </Menu>
    </div>
  );
}
