import {
  List,
  Datagrid,
  DateField,
  TextField,
  useListContext,
  useAuthenticated,
  ListContextProvider,
} from "react-admin";
import Filters from "./Filters";
import { Fragment, useCallback } from "react";
import { Divider, Tabs, Tab } from "@material-ui/core";
import { EmptyList } from "../../General/views/EmptyList";

const tabs = [
  { id: "all", name: "All Owners" },
  { id: "Active", name: "Active" },
  { id: "Block", name: "Blocked" },
];

const TabbedDatagrid = (props) => {
  const listContext = useListContext();
  const role = localStorage.getItem("role");
  const { filterValues, setFilters, displayedFilters } = listContext;
  const handleChange = useCallback(
    (event, value) => {
      setFilters &&
        setFilters({ ...filterValues, status: value }, displayedFilters);
    },
    [displayedFilters, filterValues, setFilters]
  );

  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues.status}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((tab) => (
          <Tab key={tab.id} label={`${tab.name}`} value={tab.id} />
        ))}
      </Tabs>

      <Divider />
      <ListContextProvider value={{ ...listContext }}>
        <Datagrid
          {...props}
          optimized
          rowClick="edit"
          empty={<EmptyList itemName={"Customers"} {...props} />}
        >
          <TextField source="full_name" />
          <TextField sortable={false} source="email" />
          <TextField source="username" />
          {role === "Super Admin" && (
            <TextField source="refer_user" label="Client’s Name" />
          )}
          <TextField source="postal_code" label="Postal Code" />
          <TextField source="phone" label="Contact No" />
          <DateField label="Joined on" source="createdAt" />
          <TextField source="status" label="Status" />
        </Datagrid>
      </ListContextProvider>
    </Fragment>
  );
};

export const OwnersList = (props) => {
  useAuthenticated();

  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      title="Owners"
      filters={<Filters />}
      filterDefaultValues={{ status: "all" }}
      sort={{ field: "created_at", order: "DESC" }}
    >
      <TabbedDatagrid />
    </List>
  );
};
