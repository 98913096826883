import React from "react";
import { useSelector } from "react-redux";
import List from "@material-ui/core/List";
import { NavLink } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import menu1 from "../../assets/images/menu1.png";
import menu2 from "../../assets/images/menu2.png";
import menu3 from "../../assets/images/menu3.png";
import menu4 from "../../assets/images/menu4.png";
import menu5 from "../../assets/images/menu5.png";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      position: "fixed",
      maxWidth: "240px",
      backgroundColor: "#0d1841",
      overflow: "auto",
      bottom: "0",
      top: "50px",
      color: "#ffffff",
    },
    active: {
      borderLeft: "3px solid #5A35B6",
      background: "#5A35B6",
    },
    palette: {
      primary: "#fff",
    },
    nested: {
      paddingLeft: theme.spacing(3),
    },
  })
);

const Menu = () => {
  const classes = useStyles();
  const sidebarOpen = useSelector((state) => state.admin?.ui.sidebarOpen);
  const role = localStorage.getItem("role");

  const ClientsTab = () => {
    if (role === "Super Admin") {
      return (
        <ListItem
          button
          key="clients"
          component={NavLink}
          to={"/clients"}
          activeClassName={classes.active}
        >
          <ListItemIcon>
            <img className="img-fluid " src={menu1} alt="icon" />
          </ListItemIcon>
          <ListItemText primary="Clients" />
        </ListItem>
      );
    } else {
      return null;
    }
  };

  const OwnersTab = () => {
    if (role === "Super Admin" || role === "Client") {
      return (
        <ListItem
          button
          key="owners"
          component={NavLink}
          to={"/owners"}
          activeClassName={classes.active}
        >
          <ListItemIcon>
            <img className="img-fluid " src={menu2} alt="icon" />
          </ListItemIcon>
          <ListItemText primary="Owners" />
        </ListItem>
      );
    } else {
      return null;
    }
  };

  const StaffTab = () => {
    if (role === "Super Admin" || role === "Client" || role === "Owner") {
      return (
        <ListItem
          button
          key="staff"
          component={NavLink}
          to={"/staff"}
          activeClassName={classes.active}
        >
          <ListItemIcon>
            <img className="img-fluid " src={menu3} alt="icon" />
          </ListItemIcon>
          <ListItemText primary="Staff" />
        </ListItem>
      );
    } else {
      return null;
    }
  };

  const AddUserTab = () => {
    if (role === "Super Admin" || role === "Client" || role === "Owner") {
      return (
        <ListItem
          button
          component={NavLink}
          to={"/addUser/create"}
          activeClassName={classes.active}
          key="create"
        >
          <ListItemIcon>
            <img className="img-fluid " src={menu5} alt="icon" />
          </ListItemIcon>
          <ListItemText primary="Add User" />
        </ListItem>
      );
    } else {
      return null;
    }
  };

  return (
    <List
      id="sidebar"
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root + (sidebarOpen ? " halfmenuclosed" : "")}
    >
      {[ClientsTab(), OwnersTab(), StaffTab(), AddUserTab()]}
      {/* <ListItem
        button
        component={NavLink}
        to={"/"}
        activeClassName={classes.active}
        exact
      >
        <ListItemIcon>
          <img className="img-fluid " src={menu1} alt="icon" />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem> */}

      <ListItem
        button
        component={NavLink}
        to={"/getReports"}
        activeClassName={classes.active}
      >
        <ListItemIcon>
          <img className="img-fluid " src={menu4} alt="icon" />
        </ListItemIcon>
        <ListItemText primary="Reports" />
      </ListItem>
    </List>
  );
};

export default Menu;
