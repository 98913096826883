import Menu from "./Menu";
import AppBar from "./AppBar";
import { Layout as RALayout } from "react-admin";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: 32,
    color: "#fff",
    padding: "25px 0 0 0",
  },
  spacer: {
    flex: 1,
  },
});

const Layout = (props) => {
  const classes = useStyles();

  return (
    <RALayout
      style={{ backgroundColor: "#181c1f", padding: 0, margin: 0 }}
      {...props}
      menu={Menu}
      appBar={AppBar}
    >
      <Container style={{ margin: 0, padding: 0 }}>
        <Typography
          variant="h1"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />
        {props.children}
      </Container>
    </RALayout>
  );
};

export default Layout;
