import { Tab, Tabs } from "@material-ui/core";

export const EditTabs = ({
  tabsWithDetails,
  setCurrentTabId,
  currentTabId,
}) => {
  const tabIds = Object.keys(tabsWithDetails);
  const changeTab = (event, tabId) => setCurrentTabId(tabId);

  return (
    <Tabs key="tabs" value={currentTabId} onChange={changeTab}>
      {tabIds.map((tabId) => (
        <Tab
          value={tabId}
          label={tabsWithDetails[tabId].name}
          key={`tab-${tabId}`}
        />
      ))}
    </Tabs>
  );
};
