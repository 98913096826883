import {
  Create,
  required,
  TextInput,
  PasswordInput,
  useAuthenticated,
  FormWithRedirect,
} from "react-admin";
import {
  Box,
  Grid,
  Container,
  makeStyles,
  Typography,
  createStyles,
} from "@material-ui/core";
import { EditBottomToolBar } from "../../General/utils/EditBottomToolBar";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginLeft: theme.spacing(2),
    },
    root: {
      "& .MuiTabs-root": {
        paddingBottom: "0px",
        "& .MuiButtonBase-root": {
          flex: "1",
          borderBottom: "1px solid #4C4B4C",
          maxWidth: "100%",
        },
      },
    },
  })
);

const validatePasswords = ({ password, confirm_password }) => {
  const errors = {};
  if (password && confirm_password && password !== confirm_password) {
    errors.confirm_password =
      "The password confirmation is not the same as the password.";
  }
  return errors;
};

export const CreateAdmin = (props) => {
  useAuthenticated();
  const classes = useStyles();

  return (
    <Create
      className={classes.root}
      mutationMode="pessimistic"
      basePath="/my-profile"
      resource="profile"
      id="me"
      redirect={false}
      title="Add New Admin"
      {...props}
    >
      <FormWithRedirect
        {...props}
        validate={validatePasswords}
        render={(formProps) => [
          <Container key="content" className="myforms">
            <Box component="section" className={`${classes.root} myforms`}>
              <Grid
                style={{ width: "100%", paddingBottom: "50px" }}
                container
                spacing={10}
              >
                <Grid
                  style={{ paddingBottom: "0px" }}
                  item
                  xl={12}
                  md={12}
                  sm={12}
                >
                  <Typography variant="h6" className={classes.colorTextgrey}>
                    Account Information
                  </Typography>
                </Grid>

                <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
                  <TextInput source="first_name" validate={required()} />
                </Grid>

                <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
                  <TextInput source="last_name" validate={required()} />
                </Grid>

                <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
                  <TextInput source="email" validate={required()} />
                </Grid>

                <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
                  <TextInput source="phone" />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={10}
                key="passwords"
                style={{ width: "100%", paddingBottom: "50px" }}
              >
                <Grid
                  style={{ paddingBottom: "0px" }}
                  item
                  xl={12}
                  md={12}
                  sm={12}
                >
                  <Typography variant="h6" className={classes.colorTextgrey}>
                    Update Password
                  </Typography>
                </Grid>

                <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
                  <PasswordInput
                    validate={required()}
                    inputProps={{ autocomplete: "false" }}
                    source="password"
                  />
                </Grid>

                <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
                  <PasswordInput
                    validate={required()}
                    inputProps={{ autocomplete: "false" }}
                    source="confirm_password"
                  />
                </Grid>
              </Grid>
            </Box>
          </Container>,
          <EditBottomToolBar key="toolbar" {...formProps} />,
        ]}
      />
    </Create>
  );
};
